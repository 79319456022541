<template>
  <li class="asf-list-item" v-bind="$attrs">
    <slot />
  </li>
</template>
<script lang="ts">
export default defineComponent({
  name: 'AsfListItem'
})
</script>
